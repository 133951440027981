import React from 'react'
import AnnouncementSection from '@components/AnnouncementSection'
import { useStaticQuery, graphql } from 'gatsby'

const Announcement = () => {
  const data = useStaticQuery(graphql`
    query {
      content: wpBlogPost(id: { eq: "cG9zdDozNjcwNg==" }) {
        title
        slug
        excerpt
      }
    }
  `)
  return (
    <AnnouncementSection
      heading={data.content.title}
      text={data.content.excerpt}
      link={`/blog/${data.content.slug}`}
      linkText="Read the announcement"
    />
  )
}
export default Announcement
