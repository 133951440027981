// import '../fonts/frutiger-next/font.frutiger-next.css'
import '../theme/normalize.scss'
import '../theme/base.scss'
import '../theme/root--mrs-ui.scss'
import '../theme/theme--light.scss'
import '../theme/theme--dark.scss'
import '../../utils/tailwind.css'

const UiProvider = ({ children }) => {
  return children
}
export default UiProvider
