import React from 'react'
import { StyledLinkProps } from './StyledLink.types'
import { Link } from 'gatsby'
import './styles.scss'

const StyledLink: React.FC<StyledLinkProps> = ({ to, ...props }) => {
  return (
    <Link
      to={to}
      className={`styled-link focus-outline ${
        props.className ? props.className : ''
      }`}
    >
      {props.children}
    </Link>
  )
}

export default StyledLink
