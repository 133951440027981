import React from 'react'
import { UiProvider } from '@mrs-ui'
import Layout from '@components/Layout'

const Site: React.FC = ({ children }) => {
  return (
    <UiProvider>
      <Layout>{children}</Layout>
    </UiProvider>
  )
}
export default Site
