module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MRS Company","short_name":"MRS Company","start_url":"/","background_color":"#eaeaea","theme_color":"#006bb7","display":"standalone","icon":"src/assets/images/logos/mrs/fav.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6491297971a18a2a8156d927a31dbbf7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-142559443-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://mrs-wpcms.site/graphql","html":{"useGatsbyImage":false},"schema":{"requestConcurrency":3,"timeout":100000},"type":{"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4","image/jpeg","image/jpg","image/png","image/webp"]},"Page":{"exclude":true}}},"debug":{"preview":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/github/workspace/src/App.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
