import React from 'react'
import { HeadingProps } from './Heading.types'

const Heading: React.FC<HeadingProps> = ({ type = 'h1', ...props }) => {
  let render
  switch (type) {
    case 'h1':
      render = <h1 {...props}>{props.children}</h1>
      break
    case 'h2':
      render = <h2 {...props}>{props.children}</h2>
      break
    case 'h3':
      render = <h3 {...props}>{props.children}</h3>
      break
    case 'h4':
      render = <h4 {...props}>{props.children}</h4>
      break
    case 'h5':
      render = <h5 {...props}>{props.children}</h5>
      break
    case 'h6':
      render = <h6 {...props}>{props.children}</h6>
      break
  }

  return render
}
export default Heading
