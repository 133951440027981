import React from 'react'

type Item = {
  label: string
  path?: string
}

const items: Array<Item> = [
  {
    label: 'info@mrscompany.com',
    path: 'mailto:info@mrscompany.com',
  },
  {
    label: '+1 (416)-620-2720',
    path: 'tel:4165570110',
  },
  {
    label: '305-75 International Blvd.',
    path: 'https://www.google.com/maps/place/75+International+Blvd,+Etobicoke,+ON+M9W+6L9/@43.677432,-97.5152271,4z/data=!4m5!3m4!1s0x882b39957e3adeed:0x7b2e272649cde2ba!8m2!3d43.677432!4d-79.5855402',
  },
  {
    label: 'Etobicoke, ON, Canada',
    path: 'https://www.google.com/maps/place/75+International+Blvd,+Etobicoke,+ON+M9W+6L9/@43.677432,-97.5152271,4z/data=!4m5!3m4!1s0x882b39957e3adeed:0x7b2e272649cde2ba!8m2!3d43.677432!4d-79.5855402',
  },
  {
    label: 'M9W 6L9',
    path: 'https://www.google.com/maps/place/75+International+Blvd,+Etobicoke,+ON+M9W+6L9/@43.677432,-97.5152271,4z/data=!4m5!3m4!1s0x882b39957e3adeed:0x7b2e272649cde2ba!8m2!3d43.677432!4d-79.5855402',
  },
]

const ContactMenu = () => {
  return (
    <ul className="list-none p-0">
      {items.map((i: Item) => (
        <li key={i.label} className="block mb-1.5">
          <a
            href={i.path}
            target="_blank"
            rel="noopener noreferrer"
            className="text-small text-shade700 link-transition hover:text-shade500 hover:no-underline"
          >
            {i.label}
          </a>
        </li>
      ))}
    </ul>
  )
}
export default ContactMenu
