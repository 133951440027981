import React, { useState } from 'react'
import { AnnouncementSectionProps } from './AnnouncementSection.types'

import { Container, StyledLink } from '@components/common'
import { Icon } from '@mrs-ui'

const AnnouncementSection: React.FC<AnnouncementSectionProps> = ({
  ...props
}) => {
  const [visible, setVisible] = useState(true)

  if (visible)
    return (
      <section
        className="bg-primary50 text-primary"
        aria-label="Announcement section"
      >
        <Container>
          <div className="py-10 w-auto flex items-start">
            <div className="flex-grow">
              <h3>{props.heading}</h3>
              {props.text && <p className="text-small">{props.text}</p>}
              <p className="text-small">
                <StyledLink to={props.link}>{props.linkText}</StyledLink>
              </p>
            </div>

            <button
              onClick={() => setVisible(false)}
              className="cursor-pointer flex"
              aria-label="Close announcement"
            >
              <Icon name="X" size={20} />
            </button>
          </div>
        </Container>
      </section>
    )
  else return null
}

export default AnnouncementSection
