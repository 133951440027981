import React from 'react'
import { SectionProps } from './Section.types'

import BackgroundImage from 'gatsby-background-image'
import classNames from 'classnames'

import './styles.scss'

const Section: React.FC<SectionProps> = ({
  verticalSpacing = 'xl',
  backgroundImage,
  backgroundColor = 'default',
  theme = 'light',
  className,
  ...props
}) => {
  const sectionClassNames = classNames({
    'py-gap-xxl': verticalSpacing === 'xxl',
    'py-gap-xl': verticalSpacing === 'xl',
    'py-gap-large': verticalSpacing === 'large',
    'py-gap-default': verticalSpacing === 'default',
    'py-gap-compact': verticalSpacing === 'compact',
    'py-gap-mini': verticalSpacing === 'mini',
    'theme--dark': theme === 'dark',
  })

  if (backgroundImage)
    return (
      <BackgroundImage
        fluid={backgroundImage}
        Tag="section"
        className={`${sectionClassNames} ${className ? className : ''}`}
      >
        {props.overlay && <div className="overlay" />}
        {props.children}
      </BackgroundImage>
    )
  else
    return (
      <section className={`${sectionClassNames} ${className ? className : ''}`}>
        {props.children}
      </section>
    )
}

export default Section
