import { Container } from '@components/common'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Heading } from '@mrs-ui'
import React from 'react'

import MainMenu from './MainMenu'
import ContactMenu from './ContactMenu'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      partnerLogo: file(
        relativePath: { eq: "images/logos/partners/ms_gold_logo-main.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <footer className="theme--dark pt-gap-xl pb-gap-xl">
      <Container>
        <div className="flex flex-wrap">
          <div className="md:w-1/3 w-full mb-gap-mini">
            <h3>About MRS</h3>
            <p className="text-small">
              MRS is a full-service business software company and Microsoft Gold
              Partner in Toronto, Canada, specializing in data analytics,
              Microsoft Cloud, business application development and Microsoft
              365/Office 365. For over 40 years, we've provided innovative digital
              technology solutions for SMB to enterprise clients.
            </p>
            <div className="mt-gap-default">
              <Img fluid={data.partnerLogo.childImageSharp.fluid} />
            </div>
          </div>

          <div className="md:ml-auto md:w-1/3 w-full flex flex-wrap">
            <div className="md:w-1/2 w-full mb-gap-mini">
              <Heading type="h3">Learn more</Heading>
              <MainMenu />
            </div>
            <div className="md:w-1/2 w-full">
              <h3>Contact MRS</h3>
              <ContactMenu />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between mt-gap-xl">
          <small>&copy; {new Date().getFullYear()} MRS Company Ltd.</small>

          <small>
            <Link
              to="/policies"
              className="text-contrast link-transition hover:text-shade500 hover:no-underline"
            >
              Privacy Policy & Terms of Use
            </Link>
          </small>
        </div>
      </Container>
    </footer>
  )
}
export default Footer
