import React from 'react'
import { NavbarProps } from './Navbar.types'
import './styles.scss'

import HomePlate from './HomePlate'
import MainMenu from './MainMenu'
import TopBar from './TopBar'

const Navbar: React.FC<NavbarProps> = ({ ...props }) => {
  return (
    <>
      <header role="banner" className="site-header">
        <TopBar />
        <nav
          role="navigation"
          aria-label="Global site navigation"
          className="global-nav"
        >
          <HomePlate />
          <MainMenu />
        </nav>
      </header>
      <div className="site-header__clearfix" aria-hidden={true} />
    </>
  )
}

export default Navbar
