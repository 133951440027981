import React, { useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import './styles.scss'
import MobileNavMenu from './MobileNavMenu'
import Button from '@mrs-ui/Button'
import { mainMenuItems } from '../Navbar/MainMenu'

const MobileNav = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logos/mrs/mrs_logo.png" }) {
        childImageSharp {
          fixed(height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <header className="mobile-header">
        <Link to="/" className="flex items-center">
          <Img
            fixed={data.logo.childImageSharp.fixed}
            style={{ transform: 'translateY(2px)' }}
            alt="MRS Company logo"
          />
        </Link>
        <button
          className={`mobile-header__burger ${
            menuOpen ? 'mobile-header__burger_open' : ''
          }`}
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Toggle mobile dropdown menu"
        >
          <div />
          <div />
        </button>
      </header>

      {menuOpen && (
        <div className="mobile-nav-container">
          <Button link="/contact" flex appearance="primary">
            Contact MRS
          </Button>
          <ul className="list-none w-full p-0 ">
            {mainMenuItems.map(i => (
              <li
                key={i.path}
                className="w-full block"
                onClick={() => setMenuOpen(false)}
              >
                <Link
                  to={i.path}
                  className="h-12 flex items-center text-contrast border-b border-shade100"
                >
                  {i.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}
export default MobileNav
