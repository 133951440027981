import RecentPosts from '@components/RecentPosts'
import { UiProvider } from '@mrs-ui'
import React from 'react'
import Footer from './Footer'
import MobileNav from './MobileNav'
import Navbar from './Navbar'
import Announcement from './Announcement'

const Layout = ({ children }) => {
  return (
    <UiProvider>
      <Navbar />
      <MobileNav />
      <Announcement />
      {children}
      <RecentPosts />
      <Footer />
    </UiProvider>
  )
}

export default Layout
