import React from 'react'
import { LogoRowProps } from './LogoRow.types'

import Img from 'gatsby-image/withIEPolyfill'

const LogoRow: React.FC<LogoRowProps> = ({ ...props }) => {
  return (
    <div
      style={{
        maxWidth: 'var(--navbar-max-width)',
        margin: 'auto',
        padding: '0 var(--gap-mini)',
      }}
    >
      <div className="flex justify-around flex-wrap -mx-4">
        {props.logos.map(x => (
          <div style={{ height: '64px' }} className="px-4 my-4" key={x.alt}>
            <Img
              fixed={x.image}
              alt={x.alt}
              style={{ height: '64px' }}
              objectFit="contain"
              objectPosition="left"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default LogoRow
