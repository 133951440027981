import React from 'react'
import { ButtonProps } from './Button.types'

import { Link } from 'gatsby'

import classNames from 'classnames'
import './styles.scss'
import Icon from '../Icon'

const Button: React.FC<ButtonProps> = ({
  size = 'default',
  appearance = 'default',
  shape = 'default',
  link,
  className,
  id,
  onClick,
  style,
  ...props
}) => {
  const buttonClassName = classNames({
    button: true,
    'button_size-default':
      size === 'default' && appearance !== 'link' && appearance !== 'clear',
    'button_size-compact':
      size === 'compact' && appearance !== 'link' && appearance !== 'clear',
    button_primary: appearance === 'primary',
    button_default: appearance === 'default',
    button_link: appearance === 'link',
    button_clear: appearance === 'clear',
    'button_clear_size-default': appearance === 'clear' && size === 'default',
    'button_clear_size-compact': appearance === 'clear' && size === 'compact',
    button_flex: props.flex,
    button_disabled: props.disabled,
    button_pill: shape === 'pill',
  })
  const buttonStyles = `${buttonClassName} ${className ? className : ''}`

  const sharedProps = {
    className: buttonStyles,
    id,
    'aria-label': props['aria-label'],
    ref: props.ref,
    style,
  }

  if (link)
    return (
      <Link to={link} {...sharedProps}>
        {props.actionIntent ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.children} <Icon name="ArrowRight" className="h-4 w-4 ml-2" />
          </span>
        ) : (
          props.children
        )}
      </Link>
    )
  else
    return (
      <button {...sharedProps} {...props} onClick={onClick}>
        {props.actionIntent ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.children} <Icon name="ArrowRight" className="h-4 w-4 ml-2" />
          </span>
        ) : (
          props.children
        )}
      </button>
    )
}

export default Button
