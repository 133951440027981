import React from 'react'
import { InputProps } from './Input.types'
import './styles.scss'
import classNames from 'classnames'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, type = 'text', ...props }, ref) => {
    let inputClassNames = classNames({
      input: true,
      input_error: props.error,
    })
    return (
      <div className="input-container">
        {label && (
          <label htmlFor={props.id} className="input-label">
            {label}
          </label>
        )}
        <input className={inputClassNames} ref={ref} type={type} {...props} />
        {props.helper && <div className="input-helper">{props.helper}</div>}
      </div>
    )
  }
)

export default Input
