import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

import Img from 'gatsby-image'

const HomePlate = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logos/mrs/mrs_logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Link
      to="/"
      className="inline-flex items-center h-full float-left focus-outline"
      style={{ transform: 'translateY(-2px)' }}
      aria-label="Go to mrscompany.com"
    >
      <Img
        fadeIn={false}
        fluid={data.logo.childImageSharp.fluid}
        alt="MRS Company logo"
        style={{ width: '254px' }}
      />
    </Link>
  )
}

export default HomePlate
